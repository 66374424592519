<template>
  <b-card-actions
    action-collapse
    ref="refreshCard"
    title="Daftar Sales"
    action-refresh
    @refresh="refreshStop('refreshCard')"
  >
    <!-- <b-row> -->
      <!-- <b-col md="2" sm="4" class="my-1"> -->
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-tambah
          variant="primary"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('Add') }}
        </b-button> -->
        <b-modal
          id="modal-tambah"
          size="xl"
          ok-title="Tutup"
          ok-variant="secondary"
          ok-only
          centered
          title="Form Add"
        >
          <!-- 
            cancel-variant="secondary"
            cancel-title="Tutup"-->
          <validation-observer ref="simpleRules">
            <div class="d-flex">
              <feather-icon icon="UserIcon" size="19" />
              <h6 class="mb-0 ml-50">Data Personal</h6>
            </div>
            <b-form>
              <b-row>
                <!-- Nama Lengkap -->
                <b-col cols="4">
                  <b-form-group label="Nama Lengkap" label-for="v-nama-lengkap">
                    <validation-provider
                      #default="{ errors }"
                      name="nama"
                      rules="required"
                    >
                      <b-form-input
                        v-model="nama"
                        :state="errors.length > 0 ? false : null"
                        id="v-nama-lengkap"
                        placeholder="Nama Lengkap"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Jenis Kelamin -->
                <b-col cols="4">
                  <b-form-group label="Jenis Kelamin" label-for="v-jk">
                    <validation-provider #default="{ errors }" name="jk" rules="required">
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="jk"
                        :options="optjk"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Nomor HP -->
                <b-col cols="4">
                  <b-form-group label="Nomor HP" label-for="v-nomor-hp">
                    <validation-provider
                      #default="{ errors }"
                      name="no_hp"
                      rules="required|min:9"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="no_hp"
                        id="v-nomor-hp"
                        placeholder="Nomor HP"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- email -->
                <b-col cols="4">
                  <b-form-group label="Email" label-for="v-email">
                    <validation-provider
                      #default="{ errors }"
                      name="email_kar"
                      rules="required|email"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="email_kar"
                        id="v-email"
                        type="email"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- NIK -->
                <b-col cols="4">
                  <b-form-group label="NIK" label-for="v-nik">
                    <validation-provider
                      #default="{ errors }"
                      name="nik"
                      rules="required|min:2"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="nik"
                        id="v-nik"
                        placeholder="NIK"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Alamat -->
                <b-col cols="4">
                  <b-form-group label="Alamat" label-for="v-alamat">
                    <validation-provider
                      #default="{ errors }"
                      name="alamat"
                      rules="required|min:2"
                    >
                      <b-form-textarea
                        :state="errors.length > 0 ? false : null"
                        v-model="alamat"
                        id="v-alamat"
                        placeholder="Alamat Lengkap"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                
                </b-row>

                <div class="d-flex mt-2">
        <feather-icon
          icon="DollarSignIcon"
          size="19"
        />
        <h6 class="mb-0 ml-50">
          Gaji
        </h6>
      </div>
                <b-row class="mt-1">
                <!-- Gaji Pokok -->
                <b-col cols="4">
                  <b-form-group label="Gaji Pokok" label-for="v-gaji-pokok">
                    <validation-provider
                      #default="{ errors }"
                      name="Gaji Pokok"
                      rules="required"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="gapok"
                        id="v-gaji-pokok"
                        placeholder="Gaji Pokok"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Tunjangan -->
                <b-col cols="4">
                  <b-form-group label="Tunjangan" label-for="v-tunjangan">
                    <validation-provider
                      #default="{ errors }"
                      name="tunjangan"
                      rules="required"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="tunjangan"
                        id="v-tunjangan"
                        placeholder="Tunjangan"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Bonus -->
                <b-col cols="4">
                  <b-form-group label="Bonus" label-for="v-bonus">
                    <validation-provider
                      #default="{ errors }"
                      name="bonus"
                      rules="required|min:9"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="bonus"
                        id="v-bonus"
                        placeholder="Bonus"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit and reset -->
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    @click.prevent="validationForm"
                    variant="primary"
                    class="mr-1"
                  >
                    Add
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      <!-- </b-col> -->
    <!-- </b-row> -->
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          :label="$t('Sort')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('Filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>

          <template #cell(actions)="row">
            <b-button
              title="'Detail'"
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
              variant="outline-secondary"
            >
            <feather-icon icon="ListIcon"/>
            </b-button>
            <!-- <b-button size="sm" @click="row.toggleDetails" class="mr-1">
              {{ row.detailsShowing ? "Hide" : "Show" }} Details 2
            </b-button> -->
            <b-button
            title="'Ubah'"
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
              variant="outline-info"
            >
            <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button
            title="'Hapus'"
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
              variant="outline-danger"
            >
            <feather-icon icon="TrashIcon"/>
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="detailmodal.id" :title="detailmodal.title" ok-only @hide="resetdetailmodal">
        <pre>{{ detailmodal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
  import store from '@/store/index'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardActions,VBTooltip,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BCardText,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      password,
      email,
      confirmed,
      nama: "",
      jk: null,
      no_hp: null,
      nik: null,
      alamat: "",
      email_kar: null,
      optjk: [
        { value: "L", text: "Laki-laki" },
        { value: "P", text: "Perempuan" },
      ],
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          label: "Id",
        },
        {
          key: "avatar",
          label: "Avatar",
        },
        { key: "full_name", label: "Full Name", sortable: true },
        { key: "usia", label: "Usia", sortable: true },
        "email",
        // "city",
        // "start_date",
        // "salary",
        // "age",
        // "experience",
        { key: "pengalaman", label: "Pengalaman", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created() {
        this.items = [];
    },
  methods: {
    info(item, index, button) {
      this.detailmodal.title = `Row index: ${index}`;
      this.detailmodal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.detailmodal.id, button);
    },
    resetdetailmodal() {
      this.detailmodal.title = "";
      this.detailmodal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          alert("form submitted!");
        }
      });
    },
  },
};
</script>
